<template>
  <div class="px-4 w-full max-w-[793px] mx-auto lg:mt-10">
    <div v-if="fetching" class="flex justify-center items-center h-[60vh]">
      <spinner class="w-20 h-20" color="oneGreen" />
    </div>

    <div v-else>
      <h3 class="font-bold text-xl lg:text-3pxl text-idBlued">
        Document Upload for Verification
      </h3>

      <div v-for="(doc, i) in documents" :key="i">
        <DocumentForm v-model="doc.file" :doc="doc" @upload="uploadFile" />
      </div>

      <div class="flex justify-center items-center mt-4" v-if="!formReady">
        <img
          class="w-[30px] h-[30px] mr-2"
          src="@/assets/images/icons/alert.svg"
          alt=""
        />
        <div class="w-full text-left">
          Esure that you have clicked on the upload button for all attached
          documents to proceed.
        </div>
      </div>

      <div
        v-if="!$store.getters['staff/stepStatus'](5)"
        class="flex justify-end mt-8 lg:mt-16"
      >
        <button
          @click="completeUploads"
          :disabled="proceeding || !formReady"
          class="rounded-lg bg-oneGreen py-2.5 w-full text-white px-12 flex justify-center items-center gap-2"
        >
          <spinner v-if="proceeding"></spinner>
          <span>Proceed</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import DocumentForm from "@/components/staff/verification/DocumentForm.vue";
import { fetchStaffDocuments } from "@/services/sourceData";
const emptyStaffDoc = {
  acceptedBy: null,
  acceptedDate: null,
  approvalDate: null,
  approvalStatus: null,
  approvedBy: null,
  createdAt: null,
  documentName: null,
  documentType: null,
  documentTypeId: null,
  documentUrl: null,
  employeeId: null,
  rejectReason: null,
  rejectedBy: null,
  rejectedDate: null,
  updatedAt: null,
  verificationCode: null,
};
export default {
  name: "DocumentUpload",

  components: {
    DocumentForm,
  },

  data() {
    return {
      proceeding: false,
      documents: this.$store.state.staff.docSetup,
      staffDocs: [],
      fetching: true,
    };
  },

  computed: {
    step() {
      return this.$store.getters["staff/step"];
    },
    formReady() {
      return !this.documents.some((el) => el.documentName === null);
    },
  },

  async created() {
    this.staffDocs = await fetchStaffDocuments();

    this.documents = this.documents.map((d) => {
      const df = this.staffDocs.find((g) => d._id === g.documentTypeId);
      if (df) {
        d.uploaded = true;
        d = { ...df, ...d };
      } else {
        d.uploaded = false;
        d = { ...emptyStaffDoc, ...d };
      }
      d.file = null;
      d.uploading = false;
      return d;
    });

    this.fetching = false;
  },

  methods: {
    async uploadFile(doc) {
      try {
        doc.uploading = true;
        const documents = new FormData();

        documents.append(doc.name, doc.file);
        documents.append("documentId", doc._id);

        const res = await this.$http.post("/employee/upload", documents, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (!res) {
          doc.uploading = false;
          return;
        }

        const { data } = res;
        doc.uploaded = true;
        doc.documentName = data.data.documentName;
        doc.documentType = data.data.documentType;
        doc.documentTypeId = data.data.documentTypeId;
        doc.documentUrl = data.data.documentUrl;
        doc.employeeId = data.data.employeeId;
        doc.verificationCode = data.data.verificationCode;
        doc.approvalStatus = "pending";
        doc.uploading = false;

        this.$swal({
          icon: "success",
          text: data.message,
        });
      } catch (error) {
        doc.uploading = false;
      }
    },
    async completeUploads() {
      if (!this.formReady) {
        this.$swal({
          icon: "error",
          text: "Fill all fiields",
        });
        return;
      }
      try {
        this.proceeding = true;

        const res = await this.$http.post("/employee/complete-docs");

        this.proceeding = false;
        if (!res) {
          return;
        }

        // const { data } = res;

        this.$store.commit("staff/updateVerifyStep", 6);
        this.$router.push({ name: "Pension Bvn Verification" });

        this.$swal({
          icon: "success",
          text: "Document uploads successfully done",
        });
      } catch (error) {
        this.proceeding = false;
      }
    },
  },
};
</script>

<style scoped></style>
